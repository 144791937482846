(function() {
  const ScatterChartBuilder = {};

  ScatterChartBuilder.margin = { top: 5, right: 0, bottom: 90, left: 0 };
  ScatterChartBuilder.scatterChart = function(elem, margin, data, optionxDomain) {
    /// format millions to be two digits
    const formatValue = d3.format("s");

    const width = $(elem).width() - margin.left - margin.right;
    const height = $(elem).height() - margin.top - margin.bottom;

    let maxInData = 0;

    if (data && data.length > 0) { maxInData = $.map(data, function(a, b) { if (a.industry) { return 0; } else { return a.x; } }).reduce(function(a, b) { return Math.max(a, b); }); }

    // x-axis domain and range
    const x = d3.scale.log()
      .base(10)
      .domain([0.1, 1010])
      .range([-20, $(elem).width() + 140])
      .nice();

    // y-axis domain and range
    const y = d3.scale.linear()
      .domain([20, 110])
      .range([$(elem).height() - margin.top - margin.bottom - 20, 0]);

    const xAxis = d3.svg.axis()
      .scale(x)
      .tickSize(-height, 0)
      .tickFormat(function(d) {
        return formatValue(d);
      })
      .tickValues([0.1, 1, 10, 100, 1000])
      .orient("bottom");

    // draw the y axis
    const yAxis = d3.svg.axis()
      .scale(y)
      .ticks(5)
      .tickSize(-width, 0)
      .orient("left");
      // .tickPadding(10);

    // create svg element
    const chart = d3.select(elem)
      .append("svg:svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .attr("class", "chart");

    // add white background rectangle to svg
    chart.append("rect")
      .attr("width", "100%")
      .attr("height", height + margin.top)
      .style("fill", "white")
      .attr("transform", "translate(" + margin.left + ", -" + margin.top + ")");

    const main = chart.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("width", width)
      .attr("height", height)
      .attr("class", "main");

    main.append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    main.append("g")
      .attr("class", "y-axis")
      .call(yAxis);

    // const g = main.append("svg:g");

    // remove first and last ticks on x-axis
    d3.select("" + elem + " .x-axis").selectAll(".tick")
      .each(function(d, i) {
        if (d == 0 || i == d3.select("" + elem + " .x-axis").selectAll(".tick")[0].length) {
          this.remove();
        }
      });

    // y-axis text positioning
    d3.select(elem + " .y-axis").selectAll(".tick text").style("transform", "translate(35px, -10px)");

    const g = main.append("svg:g");
    ScatterChartBuilder.updateCircles(g, data, x, y, elem, optionxDomain);
  };

  ScatterChartBuilder.updateCircles = function(g, data, x, y, elem, optionxDomain) {
    // define the div for the tooltip
    const tooltip = d3.select(elem).append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    const bulletRadious = 5;
    const bulletIndustryRadious = 3;

    // add circles
    const path = g.selectAll("scatter-dots").data(data);
    path.enter().append("svg:circle")
      .attr("cx", function(d) {
        if (d.x <= 0) { return x(1); } else { return x(d.x / 1000000); }
      })
      .attr("cy", function(d) { return y(d.y); })
      .attr("r", function(d, i) {
        if (d.industry) {
          return bulletIndustryRadious;
        } else {
          return bulletRadious;
        }
      })
      .attr("class", function(d, i) {
        if (d.industry) {
          return "light-gray circle-" + i + "";
        } else if (d.color) {
          return "" + d.color + " circle-" + i + "";
        } else {
          return "light-gray circle-" + i + "";
        }
      }).on("mouseover", function(d, i) {
        // add active class to circles
        d3.select("" + elem + " .circle-" + i + "").classed("active", true);
        d3.select("" + elem + " .circle-" + i + "").attr("r", "8");

        const color = d.color;

        if (d.industry) {
          // should we do something?
        } else {
          // add tooltips
          tooltip.transition()
            .duration(300)
            .style("opacity", 1);
          tooltip.html("<h4> " + d.title + " </h4> <div class='square'><p class=" + color + "> " + d.y + " </p> </div><p> CCI Score</p> ")
            .style("left", ((d3.event.layerX) + 30) + "px")
            .style("top", ((d3.event.layerY) - 50) + "px");
        }
      }).on("mouseout", function(d, i) {
        const rSize = d.industry ? bulletIndustryRadious : bulletRadious;
        d3.select("" + elem + " .circle-" + i + "").attr("r", rSize);
        d3.select("" + elem + " .circle-" + i + "").classed("active", false);

        tooltip.transition().duration(300).style("opacity", 0);
      });

    if (ScatterChartBuilder.filterEnabled == true) {
      path.on("click", function(d, i) {
        if (!d.industry) {
          let newData = null;

          if (ScatterChartBuilder.filtered == true) {
            newData = ScatterChartBuilder.originalData;
            ScatterChartBuilder.filtered = false;
          } else {
            newData = ScatterChartBuilder.filterData(data, d);
            ScatterChartBuilder.filtered = true;
          }

          $(elem).empty();
          ScatterChartBuilder.scatterChart(elem, ScatterChartBuilder.margin, newData, optionxDomain);
          if (ScatterChartBuilder.filtered == true) {
            d3.select("" + elem + " .circle-" + i + "").classed("active", true);
          }
        }
      });
    }

    path.exit().remove();
  };

  ScatterChartBuilder.filterData = function(data, entry) {
    return data.filter(function(i) {
      if (i.industry) {
        return i.industry_group_id == entry.industry_group_id;
      } else {
        return i.id == entry.id;
      }
    });
    // return data.slice(0,3)
  };

  ScatterChartBuilder.build = function(elm, data, domain, opts) {
    ScatterChartBuilder.originalData = data;
    ScatterChartBuilder.filterEnabled = opts.filter;
    ScatterChartBuilder.scatterChart(elm, ScatterChartBuilder.margin, data, domain);
  };

  window.ChartBuilders = (window.ChartBuilders || {});
  window.ChartBuilders.ScatterChartBuilder = ScatterChartBuilder;
})();
